// src/requestPermission.js

import { getToken } from "firebase/messaging";
import { messaging } from "./firebaseConfig";
import axiosInstance from "./utils/axios";

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, {
        vapidKey:
          "BI1br3H5rtVkRaRluansfaAMOzpsXMzLhyU2ieURx9pTU4x-ocJBYdcyzGdNS7tlbmmgVGKoyMHCFqfbnLWA9-Y",
        // "BPsDoyZtu0knai6Q0_QOxvgwaIB9fHdUdWfa9BAQk9SWYnYSguUt4DlQCdd1nT8L-MS1CCghfXHeswJzbxFRJBk",
      });
      const response = await axiosInstance.post("notification/token-update", {
        FCM_TOKEN: token,
      });
      console.log(response);

      console.log("FCM Token:", token);
      // Send this token to your backend server
    }
  } catch (error) {
    console.error("Error in requesting permission:", error);
  }
};

export default requestNotificationPermission;
