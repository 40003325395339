// routes
import { PATH_DASHBOARD, PATH_PAGE } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import {
  ADMIN_ROLE,
  CUSTOMER_SERVICE_ROLE,
  INSTALLATION_ROLE,
  INSTALLATION_SERVICE_ROLE,
  SERVICE_ROLE,
} from "../../../config";

// ----------------------------------------------------------------------
// import { WAREHOUSE_MANAGER_ROLE, ADMIN_ROLE, FIELD_STAFF_ROLE } from 'src/config'

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("vendor"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard2"),
  menuItem: getIcon("map"),
  staff: getIcon("staff"),
  file: getIcon("file"),
  service: getIcon("service"),
  notification: getIcon("notification"),
  policy: getIcon("policy"),
  beneficiary: getIcon("beneficiary"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "dashboard",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      // --------------------------------------- SUPER ADMIN -------------------------------
      {
        title: "State (LGD)",
        path: PATH_DASHBOARD.state.root,
        icon: ICONS.menuItem,
        roles: [ADMIN_ROLE],
        children: [
          {
            title: "State",
            path: PATH_DASHBOARD.state.state,
            // icon: ICONS.menuItem,
            // roles: [ADMIN_ROLE],
          },
          {
            title: "District",
            path: PATH_DASHBOARD.state.district,
            // icon: ICONS.menuItem,
            // roles: [ADMIN_ROLE],
          },
          {
            title: "Sub District",
            path: PATH_DASHBOARD.state.subdistrict,
            // icon: ICONS.menuItem,
            // roles: [ADMIN_ROLE],
          },
          {
            title: "Village",
            path: PATH_DASHBOARD.state.village,
            // icon: ICONS.menuItem,
            roles: [ADMIN_ROLE],
          },
        ],
      },
      {
        title: "Vendor",
        path: PATH_DASHBOARD.general.vendor,
        icon: ICONS.user,
        roles: [ADMIN_ROLE],
      },

      {
        title: "Beneficiaries",
        path: PATH_DASHBOARD.benefi.root,
        icon: ICONS.beneficiary,
        roles: [ADMIN_ROLE, INSTALLATION_ROLE, INSTALLATION_SERVICE_ROLE],
        children: [
          {
            title: "Beneficiary",
            path: PATH_DASHBOARD.benefi.beneficiery,
            // icon: ICONS.menuItem,
            roles: [ADMIN_ROLE, INSTALLATION_ROLE, INSTALLATION_SERVICE_ROLE],
          },
          {
            title: "Approvals",
            path: PATH_DASHBOARD.benefi.approval,
            // icon: ICONS.menuItem,
            roles: [ADMIN_ROLE, INSTALLATION_ROLE, INSTALLATION_SERVICE_ROLE],
          },
        ],
      },
      {
        title: "Policies",
        path: PATH_DASHBOARD.general.policy,
        icon: ICONS.policy,
        roles: [ADMIN_ROLE],
      },

      {
        title: "Staff",
        path: PATH_DASHBOARD.staff.root,
        icon: ICONS.staff,
        roles: [
          ADMIN_ROLE,
          INSTALLATION_ROLE,
          SERVICE_ROLE,
          INSTALLATION_SERVICE_ROLE,
        ],
        children: [
          {
            title: "Field Engineer",
            path: PATH_DASHBOARD.staff.fieldengineer,
            // icon: ICONS.menuItem,
            roles: [
              ADMIN_ROLE,
              INSTALLATION_ROLE,
              SERVICE_ROLE,
              INSTALLATION_SERVICE_ROLE,
            ],
          },
          {
            title: "CSR",
            path: PATH_DASHBOARD.staff.customerservice,
            // icon: ICONS.menuItem,
            roles: [ADMIN_ROLE],
          },
        ],
      },
      {
        title: "Servicing",
        path: PATH_DASHBOARD.service.root,
        icon: ICONS.service,
        roles: [
          ADMIN_ROLE,
          CUSTOMER_SERVICE_ROLE,
          SERVICE_ROLE,
          INSTALLATION_SERVICE_ROLE,
        ],
        children: [
          {
            title: "All Requests",
            path: PATH_DASHBOARD.service.request,
            // icon: ICONS.menuItem,
            roles: [
              ADMIN_ROLE,
              CUSTOMER_SERVICE_ROLE,
              SERVICE_ROLE,
              INSTALLATION_SERVICE_ROLE,
            ],
          },
          {
            title: "Approvals",
            path: PATH_DASHBOARD.service.approval,
            // icon: ICONS.menuItem,
            roles: [ADMIN_ROLE, SERVICE_ROLE, INSTALLATION_SERVICE_ROLE],
          },
        ],
      },
      {
        title: "notification",
        path: PATH_DASHBOARD.general.notification,
        icon: ICONS.notification,
        roles: [
          ADMIN_ROLE,
          SERVICE_ROLE,
          INSTALLATION_SERVICE_ROLE,
          INSTALLATION_ROLE,
          CUSTOMER_SERVICE_ROLE,
        ],
      },
    ],
  },
];

export default navConfig;
