import PropTypes from "prop-types";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Paper, Typography } from "@mui/material";
// utils
import { fData } from "../../utils/formatNumber";
import getFileData from "../../utils/getFileData";

// ----------------------------------------------------------------------

SuccessFiles.propTypes = {
  fileRejections: PropTypes.array.isRequired,
};

export default function SuccessFiles({ fileRejections }) {
  // console.log(fileRejections);
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "primary.light",
        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
      }}
    >
      {fileRejections?.map((item, errors) => {
        // const { path, size, name } = getFileData(item);

        return (
          <Box key={item.name} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {item.name} - {item.size ? fData(item.size) : ""}
            </Typography>

            {/* {errors.map((error) => (
              <Box
                key={error.code}
                component="li"
                sx={{ typography: "caption" }}
              >
                {error.message}
              </Box>
            ))} */}
          </Box>
        );
      })}
    </Paper>
  );
}
