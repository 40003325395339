// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
};

export const PATH_PAGE = {
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    statemain: path(ROOTS_DASHBOARD, "/state/"),
    state: path(ROOTS_DASHBOARD, "/state"),
    district: path(ROOTS_DASHBOARD, "/district"),
    subdistrict: path(ROOTS_DASHBOARD, "/sub-district"),
    village: path(ROOTS_DASHBOARD, "/village"),
    // staff: path(ROOTS_DASHBOARD, "/staff"),
    policy: path(ROOTS_DASHBOARD, "/policy"),
    beneficiery: path(ROOTS_DASHBOARD, "/beneficiary"),
    fieldengineer: path(ROOTS_DASHBOARD, "/field-engineer"),
    // service: path(ROOTS_DASHBOARD, "/service-management"),
    vendor: path(ROOTS_DASHBOARD, "/vendor-management"),
    warehouse: path(ROOTS_DASHBOARD, "/warehouse/"),
    // staff: path(ROOTS_DASHBOARD, "/staff/"),
    admin: path(ROOTS_DASHBOARD, "/admin"),
    manager: path(ROOTS_DASHBOARD, "/manager"),
    inventory: path(ROOTS_DASHBOARD, "/inventory/"),
    notification: path(ROOTS_DASHBOARD, "/notification"),
  },
  service: {
    root: path(ROOTS_DASHBOARD, "/service-management"),

    request: path(ROOTS_DASHBOARD, "/service-management/request"),
    approval: path(ROOTS_DASHBOARD, "/service-management/approval"),
  },
  benefi: {
    root: path(ROOTS_DASHBOARD, "/beneficiary"),

    beneficiery: path(ROOTS_DASHBOARD, "/beneficiary/beneficiary"),
    approval: path(ROOTS_DASHBOARD, "/beneficiary/approval"),
  },
  state: {
    root: path(ROOTS_DASHBOARD, "/state"),

    state: path(ROOTS_DASHBOARD, "/state/state"),
    district: path(ROOTS_DASHBOARD, "/state/district"),
    subdistrict: path(ROOTS_DASHBOARD, "/state/sub-district"),
    village: path(ROOTS_DASHBOARD, "/state/village"),
  },
  staff: {
    root: path(ROOTS_DASHBOARD, "/staff"),

    admin: path(ROOTS_DASHBOARD, "/staff/admin"),
    manager: path(ROOTS_DASHBOARD, "/staff/manager"),
    executive: path(ROOTS_DASHBOARD, "/staff/executive"),
    siteInspector: path(ROOTS_DASHBOARD, "/staff/site-inspector"),
    installationEn: path(ROOTS_DASHBOARD, "/staff/installation-engineer"),
    inventoryManager: path(ROOTS_DASHBOARD, "/staff/inventory-manager"),
    fieldengineer: path(ROOTS_DASHBOARD, "/staff/field-engineer"),

    customerservice: path(
      ROOTS_DASHBOARD,
      "/staff/customer-service-representative"
    ),
  },

  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),

    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
};

export const PATH_DOCS = "";
export const API_DOCS = "";
